import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import {DialogService} from "../../services/dialog.service";
import {Mail2SmsOutFilter} from "./mail2SmsOut.filter";
import {Mail2SmsInFilter} from "./mail2SmsIn.filter";
import {ActivatedRoute, Params} from "@angular/router";
import * as moment from "moment/moment";

@Component({
  selector: 'micro-mail2sms-log-outgoing',
  templateUrl: './mail2sms-log-outgoing.component.html'
})
export class Mail2SmsLogOutgoingComponent implements OnInit, OnDestroy  {

  @Input() companyId:any;
  @Input() userId:any;
  @Input() fromEmail:any;
  @Input() toEmail:any;
  @Input() msisdn:any;
  @Input() status:any;
  @Input() smtpMailboxId:any;
  @Input() text:any;

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Mail2SmsOutFilter = new Mail2SmsOutFilter();
  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;
  msgChannel:string = undefined;

  @Input()
  embedded:false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private route: ActivatedRoute,
              private http:HttpClient,
              private lookupService:LookupService,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.msgChannel = params['msgChannel'];
        if (this.msgChannel) this.msgChannel = this.msgChannel.toUpperCase();
        this.loadSettings();
        this.filter.msgChannel = this.msgChannel;

        if (this.companyId) this.filter.companyId = this.companyId;
        if (this.userId) this.filter.userId = this.userId;
        if (this.fromEmail) this.filter.fromEmail = this.fromEmail;
        if (this.toEmail) this.filter.toEmail = this.toEmail;
        if (this.msisdn) this.filter.msisdn = this.msisdn;
        if (this.status) this.filter.status = this.status;
        if (this.smtpMailboxId) this.filter.smtpMailboxId = this.smtpMailboxId;
        if (this.text) this.filter.text = this.text;

        this.timer = setInterval(() => {
          if (this.autoRefresh) {
            this.reload();
          }
        }, 30000);
        this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
          this.saveSettings();
          this.reload();
        });
        this.reload();
      });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 50))
      ;

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/mail2sms/mo/log/${this.filter.msgChannel}`, {params:params}).subscribe(
      data => {
        let page = data as any;

        let ldate = undefined;
        for (let rec of page.content) {
          if (!ldate || rec.receivedAt !== ldate) {
            ldate = rec.receivedAt;
            rec.displayReceivedAt = true;
          } else {
            rec.displayReceivedAt = false;
          }
        }

        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  saveSettings() {
    if (this.embedded) {
      return;
    }

    let sessionSettings:any = {
      filter: this.filter.toObj(),
      settings: {
        autoRefresh: this.autoRefresh
      }
    };
    sessionStorage.setItem("mail2sms_log_out_settings", JSON.stringify(sessionSettings));

    let appSettings = {
      dateRange: {
        from: this.usageFilter.from,
        fromInfinite: this.usageFilter.fromInfinite,
        until: this.usageFilter.until,
        untilInfinite: this.usageFilter.untilInfinite,
        dateRangeUnit: this.usageFilter.dateRangeUnit
      }
    };
    localStorage.setItem("mail2sms_log_out_settings", JSON.stringify(appSettings));
  }

  loadSettings() {
    if (this.embedded) {
      return;
    }
    let sessionSettingsJson = sessionStorage.getItem("mail2sms_log_out_settings");
    let appSettings: any = JSON.parse(localStorage.getItem('mail2sms_log_out_settings'));

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.autoRefresh = sessionSettings?.settings?.autoRefresh || false;
      this.filter = new Mail2SmsOutFilter(sessionSettings.filter);
      this.addFilterListener();
    } else {
      this.saveSettings();
    }

    if (appSettings && appSettings?.dateRange) {
      let dateRange = appSettings.dateRange;

      this.usageFilter.dateRangeUnit = dateRange?.dateRangeUnit;
      if (!dateRange?.dateRangeUnit) dateRange.dateRangeUnit = 'day';
      if (dateRange?.dateRangeUnit === 'custom') {
        this.usageFilter.from = dateRange?.from;
        this.usageFilter.until = dateRange?.until;
        this.usageFilter.fromInfinite = dateRange?.fromInfinite;
        this.usageFilter.untilInfinite = dateRange?.untilInfinite;
      } else {
        this.usageFilter.from = moment().add(-1, dateRange.dateRangeUnit).startOf(dateRange.dateRangeUnit).toDate().getTime();
        this.usageFilter.until = undefined;
        this.usageFilter.fromInfinite = false;
        this.usageFilter.untilInfinite = true;
        this.usageFilter.dateRangeUnit = dateRange.dateRangeUnit;
      }
    }
  }
}
