import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {debounceTime, filter} from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import {DialogService} from "../../services/dialog.service";
import {Mail2SmsInFilter} from "./mail2SmsIn.filter";
import {MailFilter} from "../../mail/mail.filter";
import {ActivatedRoute, Params} from "@angular/router";
import {WhatsappOutgoingMsgLogFilter} from "../../chat/whatsappOutgoingMsgLogs/whatsappOutgoingMsgLog.filter";
import * as moment from "moment";

@Component({
  selector: 'micro-mail2sms-log-incoming',
  templateUrl: './mail2sms-log-incoming.component.html'
})
export class Mail2SmsLogIncomingComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Mail2SmsInFilter = new Mail2SmsInFilter();
  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;
  msgChannel:string = undefined;

  @Input() companyId:any;
  @Input() userId:any;
  @Input() fromEmail:any;
  @Input() recipient:any;
  @Input() status:any;
  @Input() showPolls:any;
  @Input() mtStatus:any;
  @Input() embedded:false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private route: ActivatedRoute,
              private http:HttpClient,
              private lookupService:LookupService,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.msgChannel = params['msgChannel'];
        if (this.msgChannel) this.msgChannel = this.msgChannel.toUpperCase();
        this.loadSettings();
        this.filter.msgChannel = this.msgChannel;

        if (this.companyId) this.filter.companyId = this.companyId;
        if (this.userId) this.filter.userId = this.userId;
        if (this.fromEmail) this.filter.fromEmail = this.fromEmail;
        if (this.recipient) this.filter.recipient = this.recipient;
        if (this.status) this.filter.status = this.status;
        if (this.showPolls) this.filter.showPolls = this.showPolls;
        if (this.mtStatus) this.filter.mtStatus = this.mtStatus;

        this.timer = setInterval(()=> {
          if (this.autoRefresh) {
            this.reload();
          }
        }, 30000);
        this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
          this.saveSettings();
          this.reload();
        });
        this.reload();
      });

  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 50))
      ;

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/mail2sms/mt/log/${this.filter.msgChannel}`, {params:params}).subscribe(
      data => {
        let page = data as any;

        let ldate = undefined;
        for (let rec of page.content) {
          if (!ldate || rec.mail2SmsReceivedAt !== ldate) {
            ldate = rec.mail2SmsReceivedAt;
            rec.displayReceivedAt = true;
          } else {
            rec.displayReceivedAt = false;
          }
        }

        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  saveSettings() {
    if (this.embedded) {
      return;
    }

    let sessionSettings:any = {
      filter: this.filter.toObj(),
      settings: {
        autoRefresh: this.autoRefresh
      }
    };
    sessionStorage.setItem("mail2sms_log_in_settings", JSON.stringify(sessionSettings));

    let appSettings = {
      dateRange: {
        from: this.usageFilter.from,
        fromInfinite: this.usageFilter.fromInfinite,
        until: this.usageFilter.until,
        untilInfinite: this.usageFilter.untilInfinite,
        dateRangeUnit: this.usageFilter.dateRangeUnit
      }
    };
    localStorage.setItem("mail2sms_log_in_settings", JSON.stringify(appSettings));
  }

  loadSettings() {
    if (this.embedded) {
      return;
    }
    let sessionSettingsJson = sessionStorage.getItem("mail2sms_log_in_settings");
    let appSettings: any = JSON.parse(localStorage.getItem('mail2sms_log_in_settings'));

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.autoRefresh = sessionSettings?.settings?.autoRefresh || false;
      this.filter = new Mail2SmsInFilter(sessionSettings.filter);
      this.addFilterListener();
    } else {
      this.saveSettings();
    }

    if (appSettings && appSettings?.dateRange) {
      let dateRange = appSettings.dateRange;

      this.usageFilter.dateRangeUnit = dateRange?.dateRangeUnit;
      if (!dateRange?.dateRangeUnit) dateRange.dateRangeUnit = 'day';
      if (dateRange?.dateRangeUnit === 'custom') {
        this.usageFilter.from = dateRange?.from;
        this.usageFilter.until = dateRange?.until;
        this.usageFilter.fromInfinite = dateRange?.fromInfinite;
        this.usageFilter.untilInfinite = dateRange?.untilInfinite;
      } else {
        this.usageFilter.from = moment().add(-1, dateRange.dateRangeUnit).startOf(dateRange.dateRangeUnit).toDate().getTime();
        this.usageFilter.until = undefined;
        this.usageFilter.fromInfinite = false;
        this.usageFilter.untilInfinite = true;
        this.usageFilter.dateRangeUnit = dateRange.dateRangeUnit;
      }
    }
  }
}
