import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Mail2SmsOutFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.companyIdChange.next(filter.companyId || '');
      this.userIdChange.next(filter.userId || '');
      this.fromEmailChange.next(filter.fromEmail || '');
      this.toEmailChange.next(filter.toEmail || '');
      this.msisdnChange.next(filter.msisdn || '');
      this.statusChange.next(filter.status || '');
      this.smtpMailboxIdChange.next(filter.smtpMailboxId || '');
      this.textChange.next(filter.text || '');
      this.anyChange.emit();
    }
  }


  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  fromEmailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get fromEmail():any {
    return this.fromEmailChange.value;
  }
  set fromEmail(value:any) {
    this.fromEmailChange.next(value);
    this.emitChange("fromEmail", value);
  }

  toEmailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get toEmail():any {
    return this.toEmailChange.value;
  }
  set toEmail(value:any) {
    this.toEmailChange.next(value);
    this.emitChange("toEmail", value);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(value:any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  smtpMailboxIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smtpMailboxId():any {
    return this.smtpMailboxIdChange.value;
  }
  set smtpMailboxId(value:any) {
    this.smtpMailboxIdChange.next(value);
    this.emitChange("smtpMailboxId", value);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(value:any) {
    this.textChange.next(value);
    this.emitChange("text", value);
  }

  msgChannelChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msgChannel():any {
    return this.msgChannelChange.value;
  }
  set msgChannel(value:any) {
    this.msgChannelChange.next(value);
    this.emitChange("msgChannel", value);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.fromEmail && this.fromEmail !== '') {
      params = params.set("fromEmail", this.fromEmail);
    }
    if (this.toEmail && this.toEmail !== '') {
      params = params.set("toEmail", this.toEmail);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }
    if (this.smtpMailboxId && this.smtpMailboxId !== '') {
      params = params.set("smtpMailboxId", this.smtpMailboxId);
    }
    if (this.text && this.text !== '') {
      params = params.set("text", this.text);
    }

    return params;
  }
  toObj():any {
    return {
      companyId: this.companyId,
      userId: this.userId,
      fromEmail: this.fromEmail,
      toEmail: this.toEmail,
      msisdn: this.msisdn,
      status: this.status,
      smtpMailboxId: this.smtpMailboxId,
      text: this.text,
      msgChannel: this.msgChannel
    }
  }

  public clear():void {
    this.companyId = undefined;
    this.userId = undefined;
    this.fromEmail = undefined;
    this.toEmail = undefined;
    this.msisdn = undefined;
    this.status = undefined;
    this.smtpMailboxId = undefined;
    this.text = undefined;
    this.msgChannel = undefined;
  }
}
